<script  setup lang="ts">
import type { Reply } from '@/types/api.js'
import type { GraphicPlace, RevenueResponse, Sale, SaleResponse } from '@/types/graphics.js'
import useFormatterPrices from '@/composables/formatter-prices.js'

definePageMeta({
  middleware: 'auth',
})

const MAX_TOTAL_SALES = 100
const MAX_SALES_VALUE = 1000000

const nuxtApp = useNuxtApp()
const globalStore = useGlobal()

const monthRef = ref(new Date().getMonth() + 1)
const salesRevenue = ref(0)
const totalSales = ref(0)
const averagePrice = ref(0)
const salesData = ref<Sale[]>([])
const salesByCategorySeries = ref([0, 0, 0])
const allSalesAreZero = ref(false)

const totalOrdersSeries = ref<{ name: string, data: number[] }>({
  name: 'Total de ventas',
  data: [0, 0, 0],
})

const revenueSeries = ref([
  {
    name: '2024',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: '2023',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
])

async function loadRevenueTotal() {
  try {
    const res = await nuxtApp.$urlEfici.post<Reply<RevenueResponse>>(`${nuxtApp.$endpoint.dashboard.sales.url}?status=${5}&?time=${2}`)

    const revenue = res.data.data
    const totalRevenue = revenue.ThisPeriod.SalesRevenue.split(',').map(Number)

    totalOrdersSeries.value.data = totalRevenue
    salesRevenue.value = Number.parseInt(revenue.ThisPeriod.AveragePrice)
    totalSales.value = Number.parseInt(revenue.ThisPeriod.NumberOfSales)
    averagePrice.value = Number.parseInt(revenue.ThisPeriod.AveragePrice)
  }
  catch (e) {
    const err = e as Error

    globalStore.alertState(true)
    globalStore.alertDataState(err.cause as string, err.message, 'error')
    return e
  }
}

async function loadRevenueAnalysis() {
  try {
    const res = await nuxtApp.$urlEfici.post<Reply<{ graphic: GraphicPlace }>>(`${nuxtApp.$endpoint.dashboard.RevenueAnalytics.url}?status=${5}`)

    revenueSeries.value.at(0)!.data = res.data.data.graphic.SalesThisYearSell
    revenueSeries.value.at(1)!.data = res.data.data.graphic.SalesPreviousYearSell
  }
  catch (e) {
    return e
  }
}

async function loadSalesPerMonth(month?: number) {
  try {
    const res = await nuxtApp.$urlEfici.post<Reply<SaleResponse>>(`${nuxtApp.$endpoint.dashboard.SalesAnalytics.url}?month=${month || monthRef.value}`)

    const sales = res.data.data

    salesData.value = [
      sales.productA,
      sales.productB,
      sales.productC,
    ]

    salesByCategorySeries.value = [
      sales.productA.sales,
      sales.productB.sales,
      sales.productC.sales,
    ]

    allSalesAreZero.value = salesByCategorySeries.value.every(sale => sale === 0)
  }
  catch (e) {
    const err = e as Error

    globalStore.alertState(true)
    globalStore.alertDataState(err.cause as string, err.message, 'error')
    return e
  }
}

async function handleLoadSalesPerMonth(month: string) {
  await loadSalesPerMonth(Number.parseInt(month))
}

function handleCalculateWidth(value: number) {
  return (value / MAX_SALES_VALUE) * 100
}

onMounted(() => {
  loadRevenueTotal()
  loadSalesPerMonth()
  loadRevenueAnalysis()

  nextTick(() => {
    window.dispatchEvent(new Event('resize'))
  })
})
</script>

<template>
  <NuxtLayout>
    <div>
      <ul class="flex space-x-2 rtl:space-x-reverse">
        <li>
          <a
            href="javascript:;"
            class="text-primary hover:underline"
          >{{ $t('dashboard') }}</a>
        </li>
        <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
          <span>{{ $t('sales') }}</span>
        </li>
      </ul>

      <div class="pt-5">
        <div class="grid gap-6 mb-6 lg:grid-cols-2">
          <GraphicsRevenue
            :sales-revenue="salesRevenue"
            :revenue-series="revenueSeries"
          />
          <div class="panel h-full">
            <GraphicsSales
              :month-ref="monthRef"
              :sales-data="salesData"
              :sales-by-category-series="salesByCategorySeries"
              :all-sales-are-zero="allSalesAreZero"
              @handle-load-sales-per-month="handleLoadSalesPerMonth"
            />
          </div>
        </div>

        <div class="mb-6 grid gap-6 grid-cols-3">
          <div class="panel h-full">
            <div class="mb-5 flex items-center dark:text-white-light">
              <h5 class="text-lg font-semibold">
                Resumen de ventas
              </h5>
            </div>
            <div class="space-y-9">
              <div class="flex items-center">
                <div class="h-9 w-9 ltr:mr-3 rtl:ml-3">
                  <div
                    class="grid h-9 w-9 place-content-center rounded-full bg-secondary-light dark:bg-secondary dark:text-secondary-light"
                  >
                    <Icon name="hugeicons:align-box-bottom-center" />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="mb-2 flex font-semibold text-white-dark">
                    <h6>
                      Número de ventas
                    </h6>
                    <p class="ltr:ml-auto rtl:mr-auto">
                      {{ totalSales }}
                    </p>
                  </div>
                  <div class="h-2 rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]">
                    <div
                      class="h-full rounded-full bg-gradient-to-r from-[#7579ff] to-[#b224ef]"
                      :style="{ width: `${Math.min((totalSales / MAX_TOTAL_SALES) * 100, 100)}%` }"
                    />
                  </div>
                </div>
              </div>
              <div class="flex items-center">
                <div class="h-9 w-9 ltr:mr-3 rtl:ml-3">
                  <div
                    class="grid h-9 w-9 place-content-center rounded-full bg-success-light dark:bg-success dark:text-success-light"
                  >
                    <Icon name="hugeicons:sale-tag-02" />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="mb-2 flex font-semibold text-white-dark">
                    <h6>
                      Ingresos por ventas
                    </h6>
                    <p class="ltr:ml-auto rtl:mr-auto">
                      {{ useFormatterPrices(salesRevenue) }}
                    </p>
                  </div>
                  <div class="h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]">
                    <div
                      class="h-full rounded-full bg-gradient-to-r from-[#3cba92] to-[#0ba360]"
                      :style="{ width: `${handleCalculateWidth(salesRevenue)}%` }"
                    />
                  </div>
                </div>
              </div>
              <div class="flex items-center">
                <div class="h-9 w-9 ltr:mr-3 rtl:ml-3">
                  <div
                    class="grid h-9 w-9 place-content-center rounded-full bg-warning-light dark:bg-warning dark:text-warning-light"
                  >
                    <Icon name="hugeicons:justice-scale-01" />
                  </div>
                </div>
                <div class="flex-1">
                  <div class="mb-2 flex font-semibold text-white-dark">
                    <h6>
                      Precio promedio
                    </h6>
                    <p class="ltr:ml-auto rtl:mr-auto">
                      {{ useFormatterPrices(averagePrice) }}
                    </p>
                  </div>
                  <div class="h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]">
                    <div
                      class="h-full w-full rounded-full bg-gradient-to-r from-[#f09819] to-[#ff5858]"
                      :style="{ width: `${handleCalculateWidth(averagePrice)}%` }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <GraphicsTotal
            :total-sales="totalSales"
            :total-orders-series="totalOrdersSeries"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import useFormatterPrices from '@/composables/formatter-prices.js'

defineProps<{ totalSales: number, totalOrdersSeries: { name: string, data: number[] } }>()

const store = useAppStore()

const totalOrders = computed(() => {
  const isDark = store.theme === 'dark' || store.isDarkMode ? true : false
  return {
    chart: {
      height: 290,
      type: 'area',
      fontFamily: 'Poppins, sans-serif',
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    colors: isDark ? ['#00ab55'] : ['#00ab55'],
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    yaxis: {
      min: 0,
      show: false,
    },
    grid: {
      padding: {
        top: 125,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.3,
        opacityTo: 0.05,
        stops: [100, 100],
      },
    },
    tooltip: {
      x: {
        show: false,
      },
    },
  }
})
</script>

<template>
  <div class="panel h-full p-0 col-span-2">
    <div class="absolute flex w-full items-center justify-between p-5">
      <div class="relative">
        <h5 class="text-lg font-semibold dark:text-white-light">
          Número de ventas
        </h5>
      </div>
      <h5 class="text-2xl font-semibold ltr:text-right rtl:text-left dark:text-white-light">
        {{ useFormatterPrices(totalSales) }}
        <span class="block text-sm font-normal">Total de ventas</span>
      </h5>
    </div>
    <div
      v-if="totalOrdersSeries.data.every(value => value === 0)"
      class="text-center text-lg text-gray-500 dark:text-white-light h-[260px] flex items-center justify-center"
    >
      <h4 class="font-bold">
        No hay datos que mostrar.
      </h4>
    </div>
    <div v-else>
      <VueApexCharts
        height="290"
        :options="totalOrders"
        :series="totalOrdersSeries"
        class="overflow-hidden rounded-lg bg-white dark:bg-black"
      >
        <!-- loader -->
        <div class="grid min-h-[290px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
          <span
            class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"
          />
        </div>
      </VueApexCharts>
    </div>
  </div>
</template>
